<template>
  <div class="card p-1">
    <b-tabs>
      <b-tab title="Tasks from GRC">
        <b-tabs justified pills>
          <b-tab title="My Tasks">
            <!-- {{ mytasks }} -->
            <div class="table-responsive">
              <table role="table" class="table table-hover">
                <thead role="rowgroup">
                  <tr role="row">
                    <th role="columnheader" scope="col">
                      <div
                        class="d-flex align-items-center justify-content-start"
                      >
                        <b-form-checkbox
                          v-model="allTasksSelect"
                          v-on:change="handleAllTaskSelectClick($event)"
                          class="mr-50"
                        >
                        </b-form-checkbox>
                        <span>Name</span>
                      </div>
                    </th>
                    <!-- <th role="columnheader" scope="col">Description</th> -->
                    <th role="columnheader" scope="col" class="text-center">
                      Status
                    </th>
                    <th role="columnheader" scope="col" class="text-center">
                      Priority
                    </th>
                    <th role="columnheader" scope="col">Created At</th>
                    <!-- <th role="columnheader" scope="col">Due Date</th> -->
                    <th
                      role="columnheader"
                      scope="col"
                      v-if="isTasksOfCurrentUser"
                    >
                      Assigned To
                    </th>
                    <th role="columnheader" scope="col" v-else>Assigned By</th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  <tr
                    class="cursor-pointer"
                    v-for="task in mytasks"
                    :key="task._id"
                    @click="gotoMyTask(task._id)"
                  >
                    <td role="cell" style="width: 9vw; padding-right: 0">
                      <div
                        style="width: 9vw"
                        class="d-flex align-items-center justify-content-start"
                      >
                        <b-form-checkbox
                          v-on:change="handleTaskSelect($event, task._id)"
                          class="mr-50"
                        ></b-form-checkbox>
                        <p
                          @click="openSidebar(task._id)"
                          class="text-left text-truncate font-weight-bolder mb-0"
                          v-b-tooltip.hover.top.v-secondary
                          :title="task.title"
                        >
                          {{ task.title }}
                        </p>
                      </div>
                    </td>

                    <!-- <td
                      aria-colindex="2"
                      role="cell"
                      style="width: 10vw; max-width: 15vw; padding-right: 0"
                      @click="openSidebar(task._id)"
                    >
                      <div
                        style="width: 10vw; max-width: 15vw"
                        class="d-flex justify-content-start"
                      >
                        <p
                          class="text-left text-truncate font-weight-bold mb-0"
                          v-b-tooltip.hover.top.v-secondary
                          :title="task.description"
                        >
                          {{ task.description }}
                        </p>
                      </div>
                    </td> -->

                    <td
                      aria-colindex="2"
                      role="cell"
                      style="padding: 0"
                      @click="openSidebar(task._id)"
                    >
                      <div class="d-flex justify-content-center">
                        <!-- <b-badge
                            :variant="getStatusColor(Number(task.status))"
                            >{{ getStatusNumber(Number(task.status)) }}</b-badge
                          >  -->
                        <b-button
                          v-b-tooltip.hover.top.v-secondary
                          :title="getStatusText(Number(task.status))"
                          :variant="
                            'flat-' + getStatusColor(Number(task.status))
                          "
                          class="btn-icon"
                        >
                          <feather-icon
                            :icon="getStatusIcon(Number(task.status))"
                          />
                        </b-button>
                      </div>
                    </td>

                    <td
                      aria-colindex="2"
                      role="cell"
                      style="padding: 0"
                      @click="openSidebar(task._id)"
                    >
                      <div class="d-flex justify-content-center">
                        <b-badge
                          :variant="getPriorityColor(Number(task.priority))"
                          >{{
                            getPriorityNumber(Number(task.priority))
                          }}</b-badge
                        >
                      </div>
                    </td>

                    <td
                      aria-colindex="2"
                      role="cell"
                      style="width: 90px; padding-right: 0px"
                      @click="openSidebar(task._id)"
                    >
                      <div
                        class="d-flex justify-content-start"
                        style="width: 90px"
                      >
                        <p class="mb-0 font-weight-bold">
                          <!-- {{ getMonthFromNumber(task.created_at) }}
                            {{ new Date(task.created_at).getDate() }}
                            {{ new Date(task.created_at).getFullYear() }} -->
                          {{ task.created_at | moment }}
                        </p>
                      </div>
                    </td>

                    <!-- <td
                      aria-colindex="2"
                      role="cell"
                      style="width: 90px; padding-right: 0px"
                      @click="openSidebar(task._id)"
                    >
                      {{ task.due_date | moment }}
                    </td> -->

                    <td
                      aria-colindex="2"
                      role="cell"
                      v-if="isTasksOfCurrentUser"
                      style="width: 100px; padding-right: 0"
                      @click="openSidebar(task._id)"
                    >
                      <div
                        class="d-flex justify-content-start"
                        style="width: 100px"
                        v-if="task.creator"
                        v-b-tooltip.hover.top.v-secondary
                        :title="
                          task.creator.firstname + ' ' + task.creator.lastname
                        "
                      >
                        <p class="mb-0 font-weight-bold text-truncate">
                          {{ task.creator ? task.creator.firstname : "" }}
                          {{ task.creator ? task.creator.lastname : "" }}
                        </p>
                      </div>
                    </td>

                    <td
                      aria-colindex="2"
                      role="cell"
                      v-else
                      style="width: 100px; padding-right: 0"
                      @click="openSidebar(task._id)"
                    >
                      <div
                        class="d-flex justify-content-start"
                        style="width: 100px"
                        v-if="task.creator"
                        v-b-tooltip.hover.top.v-secondary
                        :title="
                          task.creator.firstname + ' ' + task.creator.lastname
                        "
                      >
                        <p class="mb-0 font-weight-bold text-truncate">
                          {{ task.creator ? task.creator.firstname : "" }}
                          {{ task.creator ? task.creator.lastname : "" }}
                        </p>
                      </div>
                    </td>

                    <td
                      aria-colindex="2"
                      role="cell"
                      style="padding: 0"
                      @click="openSidebar(task._id)"
                    >
                      <div class="d-flex justify-content-center">
                        <!-- <b-button
                            @click.stop="handleDeleteClick(task._id)"
                            style="width: 100px"
                            variant="danger"
                            size="sm"
                            ><feather-icon
                              icon="TrashIcon"
                              class="mr-50"
                            /><span class="align-middle">Delete</span></b-button
                          > -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
          <b-tab title="Assigned Tasks">
            <div class="table-responsive">
              <table role="table" class="table table-hover">
                <thead role="rowgroup" class="thead-light">
                  <tr role="row" @click="gotoGrcTasks">
                    <th role="columnheader" scope="col">
                      <div
                        class="d-flex align-items-center justify-content-start"
                      >
                        <b-form-checkbox
                          v-model="allTasksSelect"
                          v-on:change="handleAllTaskSelectClick($event)"
                          class="mr-50"
                        >
                        </b-form-checkbox>
                        <span>Name</span>
                      </div>
                    </th>
                    <!-- <th role="columnheader" scope="col">Description</th> -->
                    <th role="columnheader" scope="col" class="text-center">
                      Status
                    </th>
                    <th role="columnheader" scope="col" class="text-center">
                      Priority
                    </th>
                    <th role="columnheader" scope="col">Created At</th>
                    <!-- <th role="columnheader" scope="col">Due Date</th> -->
                    <th
                      role="columnheader"
                      scope="col"
                      v-if="isTasksOfCurrentUser"
                    >
                      Assigned By
                    </th>
                    <th role="columnheader" scope="col" v-else>Assigned To</th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  <tr
                    class="cursor-pointer"
                    v-for="task in assignedtasks"
                    :key="task._id"
                     @click="gotoMyTask(task._id)"
                  >
                    <td role="cell" style="width: 9vw; padding-right: 0">
                      <div
                        style="width: 9vw"
                        class="d-flex align-items-center justify-content-start"
                      >
                        <b-form-checkbox
                          v-on:change="handleTaskSelect($event, task._id)"
                          class="mr-50"
                        ></b-form-checkbox>
                        <p
                          @click="openSidebar(task._id)"
                          class="text-left text-truncate font-weight-bolder mb-0"
                          v-b-tooltip.hover.top.v-secondary
                          :title="task.title"
                        >
                          {{ task.title }}
                        </p>
                      </div>
                    </td>

                    <!-- <td
                      aria-colindex="2"
                      role="cell"
                      style="width: 10vw; max-width: 15vw; padding-right: 0"
                      @click="openSidebar(task._id)"
                    >
                      <div
                        style="width: 10vw; max-width: 15vw"
                        class="d-flex justify-content-start"
                      >
                        <p
                          class="text-left text-truncate font-weight-bold mb-0"
                          v-b-tooltip.hover.top.v-secondary
                          :title="task.description"
                        >
                          {{ task.description }}
                        </p>
                      </div>
                    </td> -->

                    <td
                      aria-colindex="2"
                      role="cell"
                      style="padding: 0"
                      @click="openSidebar(task._id)"
                    >
                      <div class="d-flex justify-content-center">
                        <!-- <b-badge
                            :variant="getStatusColor(Number(task.status))"
                            >{{ getStatusNumber(Number(task.status)) }}</b-badge
                          > -->
                        <b-button
                          v-b-tooltip.hover.top.v-secondary
                          :title="getStatusText(Number(task.status))"
                          :variant="
                            'flat-' + getStatusColor(Number(task.status))
                          "
                          class="btn-icon"
                        >
                          <feather-icon
                            :icon="getStatusIcon(Number(task.status))"
                          />
                        </b-button>
                      </div>
                    </td>

                    <td
                      aria-colindex="2"
                      role="cell"
                      style="padding: 0"
                      @click="openSidebar(task._id)"
                    >
                      <div class="d-flex justify-content-center">
                        <b-badge
                          :variant="getPriorityColor(Number(task.priority))"
                          >{{
                            getPriorityNumber(Number(task.priority))
                          }}</b-badge
                        >
                      </div>
                    </td>

                    <td
                      aria-colindex="2"
                      role="cell"
                      style="width: 90px; padding-right: 0px"
                      @click="openSidebar(task._id)"
                    >
                      <div
                        class="d-flex justify-content-start"
                        style="width: 90px"
                      >
                        <!-- <p class="mb-0 font-weight-bold">
                            {{ getMonthFromNumber(task.created_at) }}
                            {{ new Date(task.created_at).getDate() }}
                            {{ new Date(task.created_at).getFullYear() }}
                          </p> -->
                        <p class="mb-0 font-weight-bold">
                          {{ task.created_at | moment }}
                        </p>
                      </div>
                    </td>

                    <!-- <td
                      aria-colindex="2"
                      role="cell"
                      style="width: 90px; padding-right: 0px"
                      @click="openSidebar(task._id)"
                    >
                      {{ task.due_date | moment }}
                    </td> -->

                    <td
                      aria-colindex="2"
                      role="cell"
                      v-if="isTasksOfCurrentUser"
                      style="width: 100px; padding-right: 0"
                      @click="openSidebar(task._id)"
                    >
                      <div
                        class="d-flex justify-content-start"
                        style="width: 100px"
                        v-if="task.creator"
                        v-b-tooltip.hover.top.v-secondary
                        :title="
                          task.creator.firstname + ' ' + task.creator.lastname
                        "
                      >
                        <p class="mb-0 font-weight-bold text-truncate">
                          {{ task.creator ? task.creator.firstname : "" }}
                          {{ task.creator ? task.creator.lastname : "" }}
                        </p>
                      </div>
                    </td>

                    <td
                      aria-colindex="2"
                      role="cell"
                      v-else
                      style="width: 100px; padding-right: 0"
                      @click="openSidebar(task._id)"
                    >
                      <div
                        class="d-flex justify-content-start"
                        style="width: 100px"
                        v-if="task.assignee_user"
                        v-b-tooltip.hover.top.v-secondary
                        :title="
                          task.assignee_user.firstname +
                          ' ' +
                          task.assignee_user.lastname
                        "
                      >
                        <p class="mb-0 font-weight-bold text-truncate">
                          {{
                            task.assignee_user
                              ? task.assignee_user.firstname
                              : ""
                          }}
                          {{
                            task.assignee_user
                              ? task.assignee_user.lastname
                              : ""
                          }}
                        </p>
                      </div>
                    </td>

                    <td
                      aria-colindex="2"
                      role="cell"
                      style="padding: 0"
                      @click="openSidebar(task._id)"
                    >
                      <div class="d-flex justify-content-center"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
        </b-tabs>
      </b-tab>
      <b-tab title="Tasks from TM">
        <b-tabs justified pills>
          <b-tab title="My Tasks">
            <div class="table-responsive">
              <table
                class="table"
                hover
                responsive
                v-if="!loading"
                id="my-table"
                :per-page="perPage"
              >
                <thead role="rowgroup">
                  <tr class="text-bold">
                    <th>Name</th>

                    <th>Status</th>

                    <th>Priority</th>

                    <th>Created At</th>

                    <th>Asset</th>

                    <th>Assigned By</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="task in mytaskstm"
                    :key="task.task_id"
                    :class="{ completed: task.task_status == 2 }"
                  >
                    <td>
                      <span class="todo-title">{{ task.task_title }}</span>
                    </td>

                    <td>
                      <div class="badge-wrapper mr-1">
                        <!-- Task Status -->

                        <b-badge
                          style="margin-right: 1px"
                          variant="primary"
                          v-if="task.task_status == 0"
                          >Assigned</b-badge
                        >

                        <b-badge
                          style="margin-right: 1px"
                          variant="info"
                          v-else-if="task.task_status == 4"
                          >Re-opened</b-badge
                        >

                        <b-badge
                          style="margin-right: 1px"
                          variant="warning"
                          v-else-if="task.task_status == 1"
                          >In Progress</b-badge
                        >

                        <b-badge
                          style="margin-right: 1px"
                          variant="success"
                          v-else-if="task.task_status == 2"
                          >Completed</b-badge
                        >

                        <b-badge
                          style="margin-right: 3px"
                          variant="secondary"
                          v-else
                          >UnKnown</b-badge
                        >
                      </div>
                    </td>

                    <td>
                      <div class="badge-wrapper">
                        <!-- Task Priority -->

                        <b-badge
                          style="margin-right: 1px"
                          variant="primary"
                          v-if="task.task_priority == 1"
                          >Low</b-badge
                        >

                        <b-badge
                          style="margin-right: 1px"
                          variant="info"
                          v-else-if="task.task_priority == 2"
                          >Medium</b-badge
                        >

                        <b-badge
                          style="margin-right: 1px"
                          variant="warning"
                          v-else-if="task.task_priority == 3"
                          >High</b-badge
                        >

                        <b-badge
                          style="margin-right: 1px"
                          variant="danger"
                          v-else-if="task.task_priority == 4"
                          >Critical</b-badge
                        >

                        <b-badge variant="secondary" v-else>UnKnown</b-badge>
                      </div>
                    </td>

                    <td>
                      <span>{{ task.created_at | formatdate }}</span>
                    </td>

                    <td>
                      {{ task.asset_profile.asset_nickname }}
                    </td>

                    <td>
                      <small
                        class="text-nowrap text-primary mr-1"
                        v-if="task.task_assigned_by"
                        ><b>{{ task.task_assigned_by.full_name }}</b>
                      </small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
          <b-tab title="Assigned Tasks">
            <div class="table-responsive">
              <table
                class="table"
                hover
                responsive
                v-if="!loading"
                id="my-table"
                :per-page="perPage"
              >
                <thead>
                  <tr class="text-bold">
                    <th>Name</th>
                    <th>Status</th>
                    <th>Priority</th>
                    <th>Created At</th>
                    <th>Asset</th>
                    <th>Assigned To</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="task in assignedtaskstm"
                    :key="task.task_id"
                    :class="{ completed: task.task_status == 2 }"
                  >
                    <td>
                      <span class="todo-title">{{ task.task_title }}</span>
                    </td>

                    <td>
                      <div class="badge-wrapper mr-1">
                        <!-- Task Status -->

                        <b-badge
                          style="margin-right: 1px"
                          variant="primary"
                          v-if="task.task_status == 0"
                          >Assigned</b-badge
                        >

                        <b-badge
                          style="margin-right: 1px"
                          variant="info"
                          v-else-if="task.task_status == 4"
                          >Re-opened</b-badge
                        >

                        <b-badge
                          style="margin-right: 1px"
                          variant="warning"
                          v-else-if="task.task_status == 1"
                          >In Progress</b-badge
                        >

                        <b-badge
                          style="margin-right: 1px"
                          variant="success"
                          v-else-if="task.task_status == 2"
                          >Completed</b-badge
                        >

                        <b-badge
                          style="margin-right: 3px"
                          variant="secondary"
                          v-else
                          >UnKnown</b-badge
                        >
                      </div>
                    </td>

                    <td>
                      <div class="badge-wrapper">
                        <!-- Task Priority -->

                        <b-badge
                          style="margin-right: 1px"
                          variant="primary"
                          v-if="task.task_priority == 1"
                          >Low</b-badge
                        >

                        <b-badge
                          style="margin-right: 1px"
                          variant="info"
                          v-else-if="task.task_priority == 2"
                          >Medium</b-badge
                        >

                        <b-badge
                          style="margin-right: 1px"
                          variant="warning"
                          v-else-if="task.task_priority == 3"
                          >High</b-badge
                        >

                        <b-badge
                          style="margin-right: 1px"
                          variant="danger"
                          v-else-if="task.task_priority == 4"
                          >Critical</b-badge
                        >

                        <b-badge variant="secondary" v-else>UnKnown</b-badge>
                      </div>
                    </td>

                    <td>
                      <span>{{ task.created_at | formatdate }}</span>
                    </td>

                    <td>
                      {{ task.asset_profile.asset_nickname }}
                    </td>

                    <td>
                      <small
                        class="text-nowrap text-primary mr-1"
                        v-if="task.task_assigned_to"
                        ><b>{{ task.task_assigned_to.full_name }}</b>
                      </small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BButton,
  VBTooltip,
  BTabs,
  BTab,
} from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BButton,
    VBTooltip,
    BTabs,
    BTab,
    moment,
  },
  data() {
    return {
      mytasks: [],
      assignedtasks: [],
      mytaskstm: [],
      assignedtaskstm: [],
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("Do MMMM YYYY");
    },
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY hh:mm A");
      }
    },
  },

  mounted() {
    if (this.$store.state.app.currentWorkspaceModules.includes("GRC")) {
      this.getMyTasks();
      this.getAssignedTasks();
    }
    if (
      this.$store.state.app.currentWorkspaceModules.includes(
        "Threat Management"
      )
    ) {
      this.getMytasksTm();
      this.getAssignedTasksTm();
    }
  },
  methods: {
    gotoMyTask(id) {
      this.switchApp(
        process.env.VUE_APP_GRC_WEB_URL,
        `tasks?task_id=${id}`
      );
    },

    switchApp(appUrl, route) {
      this.$cookies.set(
        "redirectTMPath",
        route,
        null,
        null,
        process.env.VUE_APP_DOMAIN
      );
      const url = `${appUrl}${route}`;
      window.open(url, "_blank");
    },

    gotoGrcTasks() {
      this.switchApp(process.env.VUE_APP_GRC_WEB_URL, "tasks");
    },
    getMytasksTm() {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "org-id": this.$store.state.app.tenantId,
        },
        url: `${process.env.VUE_APP_TM_API_URL}task/task/my-tasks`,
      };
      this.$http(options)
        .then((resp) => {
          this.mytaskstm = resp.data.results;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    getAssignedTasksTm() {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "org-id": this.$store.state.app.tenantId,
        },
        url: `${process.env.VUE_APP_TM_API_URL}task/task/my-tasks-created`,
      };
      this.$http(options)
        .then((resp) => {
          this.assignedtaskstm = resp.data.results;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    getMyTasks() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_CISO_API_URL}/tasks?assignee_id=${this.$store.state.app.user.user_id}&page=1&status=1&limit=5`,
      };
      this.$http(options)
        .then((resp) => {
          this.mytasks = resp.data.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    getAssignedTasks() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_CISO_API_URL}/tasks?created_by=${this.$store.state.app.user.user_id}&page=1&status=1&limit=5`,
      };
      this.$http(options)
        .then((resp) => {
          this.assignedtasks = resp.data.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    getStatusIcon(value) {
      if (value && value !== null) {
        if (value == 1) {
          return "ClockIcon";
        } else if (value == 2) {
          return "TrendingUpIcon";
        } else if (value == 3) {
          return "EyeIcon";
        } else if (value == 4) {
          return "ThumbsUpIcon";
        } else if (value == 5) {
          return "SlashIcon";
        }
      }
    },

    getStatusText(value) {
      if (value && value !== null) {
        if (value == 1) {
          return "Pending";
        } else if (value == 2) {
          return "In Progress";
        } else if (value == 3) {
          return "In Review";
        } else if (value == 4) {
          return "Approved";
        } else if (value == 5) {
          return "Rejected";
        }
      }
    },
    getStatusNumber(num) {
      switch (num) {
        case 1:
          return "Pending";
        case 2:
          return "In Progress";
        case 3:
          return "In Review";
        case 4:
          return "Approved";
        case 5:
          return "Rejected";
        default:
          return "Pending";
      }
    },
    getStatusColor(num) {
      switch (num) {
        case 1:
          return "info";
        case 2:
          return "primary";
        case 3:
          return "warning";
        case 4:
          return "success";
        case 5:
          return "danger";
        default:
          return "info";
      }
    },
    getPriorityColor(num) {
      switch (num) {
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "danger";
        default:
          return "success";
      }
    },
    getPriorityNumber(num) {
      switch (num) {
        case 1:
          return "Low";
        case 2:
          return "Medium";
        case 3:
          return "High";
        default:
          return "Low";
      }
    },
  },
};
</script>